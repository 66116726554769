<template>
    <div>
        <slot :payload="{
            loading,
            lessons,
            courses,
            supervisors,
            supervising,
            specialization,
            user
        }" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    props: {
        user: { required: true, type: Object },
        period: { required: false, type: Number, default: null }
    },
    computed: {
        ...mapState({
            teacher: ({ teachers }) => teachers.current,
            loading: ({ teachers }) => teachers.loading
        }),
        courses() {
            return (this.teacher?.course_sections ?? [])
                .filter($0 => this.period ? $0.period_id === this.period : true)
        },
        lessons() {
            return (this.teacher?.lessons ?? [])
                .filter($0 => this.period ? $0.period_id === this.period : true)
        },
        /**
         * @description just for teacher
         */
        supervisors() {
            /**
             * in this case there is not a default value
             * because is better to use its null value to
             * validate existence
             */
            return this.teacher?.supervisors ?? []
        },
        /**
         * @description just for coordinator
         */
        supervising() {
            return this.teacher?.supervising ?? []
        },
        /**
         * @description just for coordinator
         */
        specialization() {
            return this.teacher?.coordinate_specialization
        }
    },
    methods: mapActions({
        fetch: 'teachers/show'
    }),
    mounted() {
        this.fetch({
            id: this.user.teacher.id,
            // with: 'supervisors', 'supervisors.coordinateSpecialization, 'coordinateSpecialization'
            filters: {
                with: `lessons.courseSection.course,lessons.courseSection.section,lessons.courseSection.specialization,courseSections.course,courseSections.section,courseSections.specialization,courseSections.semester`
            }
        })
    }
}
</script>
