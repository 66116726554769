<template>
    <div>
        <div class="bg-white rounded-2xl">
            <div class="flex justify-between items-center">
                <h2 class="text-2xl p-4">
                    Materias cursantes <template v-if="selectedPeriod"> del periodo {{ selectedPeriod.year }}-{{ selectedPeriod.number }}</template>
                </h2>
                <p v-if="average" class="text-lg">Promedio: {{ average }}</p>
            </div>
            <a-table :source="courses" :columns="columns" rounded :loading="loading">
              <template #td(course)="{ item }">
                <span >{{ item.course.title }}</span>
              </template>
              <template #td(semester)="{ item }">
                <span >{{ item.course.code.slice(-1) }}</span>
              </template>
              <template #td(final_delivery)="{ item }">
                <span :class="item.pivot.failed_by_final_delivery ? 'text-red-500' : ''">{{ item.pivot.failed_by_final_delivery ? 'No Entregó' : item.final_delivery ? item.pivot.final_delivery_note != 0 ? item.pivot.final_delivery_note : '-' : 'N/A' }}</span>
              </template>
              <template #td(status)="{ item }">
                <span >{{ getStatus(item.pivot.status) }}</span>
              </template>
<!--                <template #last-cell-footer>-->
<!--                    <p class="text-right" v-if="!loading">-->
<!--                        <b>Total: {{ courses.reduce((a, b) => a + b.credits, 0) }}</b>-->
<!--                    </p>-->
<!--                    <a-skeleton v-else height="30px"></a-skeleton>-->
<!--                </template>-->
              <template #td(actions)="{ item }">
                <a-dropdown>
                  <template #menu>
                    <a-dropdown-item :to="{ name: 'profile.show.course', params: { section_id: item.section_id, course_id: item.course_id } }">Ver notas</a-dropdown-item>
                  </template>
                </a-dropdown>
              </template>
            </a-table>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        courses: { required: false, type: Array, default: () => [] },
        loading: { required: false, type: Boolean, default: false },
        selectedPeriod: { required: false, type: Object, default: () => ({}) },
        user: { required: false, type: Object, default: () => ({}) },
        periodId: { required: false, type: Object },
    },
    // computed: {
    //     getAverage (){
    //         this.$repository.students
    //             .periodAverageNote(this.user, { period_id: parseInt(this.selectedPeriod) })
    //             .then((ress) => {
    //                 console.log(ress)
    //             })
    //         return 15
    //     }
    // },
    data: () => ({
        columns: [
            { title: "Código", key: 'course.code' },
            { title: "Título", slot: 'course' },
            { title: "Semestre", slot: 'semester' },
            { title: "Primer corte", key: 'pivot.first_cut_note' },
            { title: "Segundo corte", key: 'pivot', mutate: ({ key }) => key.second_cut_note ? key.second_cut_note : '-' },
            { title: "Nota acumulada", key: 'pivot.final_note_raw'},
            { title: "Nota entrega final", slot: 'final_delivery'},
            { title: "Nota final", key: 'pivot', mutate: ({ key }) => key.overwritten_final_note !== null ? key.overwritten_final_note : key.final_note },
            { title: "Créditos", key: 'course.credits', alignment: 'right' },
            { title: "status", key: 'pivot.status', slot: 'status' },
            { title: "Acciones", slot: 'actions' },
        ],
        average: null
    }),
    methods: {
      getStatus(status) {
        // if (failedByAbsences) {
        //   return 'Reprobada por inasistencias'
        // } else {
        switch(status) {
          case 'withdrawn': return 'Retirada'
          case 'withdrawn_by_equivalence': return 'Retirado por equivalencia'
          case 'assigned': return 'Asignada'
        }
        // }
      },
    },
    watch: {
        user (value) {
            if (value) {
                this.$repository.students
                    .periodAverageNote(value.id, { period_id: this.periodId.latestInscription.period_id})
                    .then(({ data: response }) => {
                        this.average = response.average_note
                    })
            }
            // this.$repository.students
            //     .periodAverageNote(value.id, { period_id: parseInt(this.selectedPeriod) })
            //     .then((response) => {
            //         console.log(response)
            //         this.average = response
            //     })
        }
    },
}
</script>
