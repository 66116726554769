var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default",null,{"payload":{
        loading: _vm.loading,
        lessons: _vm.lessons,
        courses: _vm.courses,
        supervisors: _vm.supervisors,
        supervising: _vm.supervising,
        specialization: _vm.specialization,
        user: _vm.user
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }