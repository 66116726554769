var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default",null,{"payload":{
        binnacles: _vm.binnacles,
        absences: _vm.absences,
        delays: _vm.delays,
        lessons: _vm.lessons,
        latestInscription: _vm.latestInscription,
        courseSections: _vm.courseSections,
        loading: _vm.loading,
        student: _vm.student
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }