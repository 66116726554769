var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white rounded-2xl"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h2',{staticClass:"text-2xl p-4"},[_vm._v(" Materias cursantes "),(_vm.selectedPeriod)?[_vm._v(" del periodo "+_vm._s(_vm.selectedPeriod.year)+"-"+_vm._s(_vm.selectedPeriod.number))]:_vm._e()],2),(_vm.average)?_c('p',{staticClass:"text-lg"},[_vm._v("Promedio: "+_vm._s(_vm.average))]):_vm._e()]),_c('a-table',{attrs:{"source":_vm.courses,"columns":_vm.columns,"rounded":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"td(course)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.course.title))])]}},{key:"td(semester)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.course.code.slice(-1)))])]}},{key:"td(final_delivery)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.pivot.failed_by_final_delivery ? 'text-red-500' : ''},[_vm._v(_vm._s(item.pivot.failed_by_final_delivery ? 'No Entregó' : item.final_delivery ? item.pivot.final_delivery_note != 0 ? item.pivot.final_delivery_note : '-' : 'N/A'))])]}},{key:"td(status)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getStatus(item.pivot.status)))])]}},{key:"td(actions)",fn:function(ref){
var item = ref.item;
return [_c('a-dropdown',{scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('a-dropdown-item',{attrs:{"to":{ name: 'profile.show.course', params: { section_id: item.section_id, course_id: item.course_id } }}},[_vm._v("Ver notas")])]},proxy:true}],null,true)})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }