<template>
  <div>
    <h2 class="text-2xl p-4 mb-2">Supervisores</h2>
    <div class="flex flex-wrap -mx-2">
      <div class="w-1/2 lg:w-1/3 xl:w-1/5 px-2" v-for="supervisor in supervisors" :key="supervisor.id">
        <a-card>
          <div class="flex items-center flex-col justify-center truncate">
            <a-avatar :text="supervisor.first_name" :picture="supervisor.profile_picture" size="64" class="mb-4"/>

            <p class="font-bold text-lg truncate">
              {{ `${supervisor.first_name} ${supervisor.last_name}` }}
            </p>
            <p class="font-bold text-lg truncate">
              {{ supervisor.coordinate_specialization.title }}
            </p>
            <p class="truncate">
              {{ supervisor.phone }}
            </p>
            <p>
              {{ supervisor.email }}
            </p>
          </div>
        </a-card>
      </div>
      <div class="w-full text-center" v-if="!supervisors.length && !loading">
        <h2 class="text-2xl mt-4">No tienes ningún supervisor</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: { required: false, type: Boolean, default: false },
    user: { required: true, type: Object },
    supervisors: { required: false, type: Array, default: () => [] }
  }
}
</script>

<style scoped>

</style>
