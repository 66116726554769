<template>
    <div>
        <div class="bg-white rounded-2xl overflow-hidden">
            <h2 class="text-2xl p-4">Bitácoras registradas</h2>
            <a-table :source="binnacles" :columns="columns" rounded :loading="loading" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        binnacles: { required: false, type: Array, default: () => [] },
        loading: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        columns: [
            // { title: "perido", key: 'period', mutate: ({ key }) => `${key.year} ${key.number}` },
            { title: "descripción", key: 'description' },
            { title: "materia", key: 'course_section.course.title' },
            { title: "sección", key: 'course_section.section.letter_code' },
            { title: "profesor", key: 'teacher', mutate: ({ key }) => `${key.first_name} ${key.last_name}` },
            { title: "especialidad", key: 'course_section.specialization.title' },
        ]
    })
}
</script>