<template>
    <div class="w-full text-center">
<!--        <soon />-->
      <div class="flex justify-between mb-4">
        <h2 class="text-2xl">Datos generales</h2>
        <h2 class="text-lg" v-if="periodFull.year && periodFull.number">Periodo activo:<span class="font-bold">{{ ' '+ periodFull.year + '-' + periodFull.number }}</span></h2>
      </div>
      <section class="flex w-full" v-if="!loading">
        <div class="w-3/5 flex flex-col">
          <div class="w-1/2 md:w-1/3 xl:w-full px-2">
            <a-card>
              <div class="divide-y">
                <h3 class="text-center">Cantidad de estudiantes</h3>
                <p class="text-3xl text-center font-bold">
                  {{ studentsCount }}
                </p>
              </div>
            </a-card>
          </div>
          <div class="w-1/2 md:w-1/3 xl:w-full px-2 pt-6">
            <a-card>
              <div class="divide-y">
                <h3 class="text-center">Estudiantes inscritos</h3>
                <p class="text-3xl text-center font-bold">
                  {{ totalPeriodCount }}
                </p>
              </div>
            </a-card>
          </div>
        </div>
        <div class="w-3/5 flex flex-col">
          <div class="w-1/2 md:w-1/3 xl:w-full px-2">
            <a-card>
              <div class="divide-y">
                <h3 class="text-center">Cantidad de profesores</h3>
                <p class="text-3xl text-center font-bold">
                  {{ teachersCount }}
                </p>
              </div>
            </a-card>
          </div>
          <div class="w-1/2 md:w-1/3 xl:w-full px-2 pt-6">
            <a-card>
              <div class="divide-y">
                <h3 class="text-center">Cantidad de aulas</h3>
                <p class="text-3xl text-center font-bold">
                  {{ classroomsCount }}
                </p>
              </div>
            </a-card>
          </div>
        </div>

<!--        <div class="w-1/2 md:w-1/3 xl:w-1/2 px-2">-->
<!--          <a-card>-->
<!--            <div class="divide-y">-->
<!--              <h3 class="text-center">Periodo actual</h3>-->
<!--              <p class="text-3xl text-center font-bold">-->
<!--                {{ period }}-->
<!--              </p>-->
<!--            </div>-->
<!--          </a-card>-->
<!--        </div>-->
      </section>
    </div>
</template>

<script>
// import Soon from '../../../soon'
import { mapState } from "vuex";

export default {
  name: 'Info',
  props: {
    periodFull: { required: false, type: Object, default: null }
  },
  data() {
    return {
      loading: false,
      dashboardValues: [],
      studentsCount: 0,
      teachersCount: 0,
      classroomsCount: 0,
      totalPeriodCount: 0
    }
  },
  computed: {
    ...mapState({
      actualPeriod: state => state.periods.pagination.data
    })
  },
  mounted() {
    this.loading = true
    this.$repository.semesters
      .dashboard()
      .then(({ data:response }) => {
        this.dashboardValues = JSON.parse(JSON.stringify(response))
        this.studentsCount = this.dashboardValues?.students?.total_count
        this.teachersCount = this.dashboardValues?.teachers?.total_count
        this.classroomsCount = this.dashboardValues?.classrooms?.total_count
        this.totalPeriodCount = this.dashboardValues?.students?.total_period_count ? this.dashboardValues.students.total_period_count : 0
      })
      .finally(() => {
        this.loading = false
      })
  }
    // components: {
    //     Soon
    // }
}
</script>

<style scoped>

</style>
