<template>
    <div class="flex flex-wrap justify-center -mx-2">
        <div class="w-full grid gap-4 pb-4" :class="{
            'grid-cols-4': payload.user.isCoordinator,
            'grid-cols-3': payload.user.isTeacher
        }">
            <a-card :loading="payload.loading" class="select-none cursor-default" v-if="payload.user.isCoordinator">
                <div class="divide-y flex items-center justify-center flex-col">
                    <h3 class="text-center w-full pb-2">Especialidad</h3>
                    <template v-if="payload.specialization">
                        <p class="text-3xl flex items-center pt-2 justify-center font-bold">
                            <span class="inline-block h-4 w-4 mr-2 rounded-full" :style="{
                                background: payload.specialization.color,
                                color: payload.specialization.text_color
                            }"></span>
                            {{ payload.specialization.title }}
                        </p>
                    </template>
                    <p v-else>Sin especialidad</p>
                </div>
            </a-card>
<!--            <a-card class="cursor-default relative select-none overflow-hidden" :loading="payload.loading" v-if="payload.user.isTeacher">-->
<!--                <div class="absolute backdrop-blur-5 bg-white flex justify-center items-center bg-opacity-50 inset-0" v-if="loadings.supervisor">-->
<!--                    <a-loader class="text-primary" size="3" />-->
<!--                </div>-->
<!--                <div class="divide-y flex items-center justify-center flex-col">-->
<!--                    <div>-->
<!--                        <h3 class="text-center w-full pb-2 text-sm" v-if="selected.specialization">-->
<!--                            Coordinador de {{ selected.specialization.title }}-->
<!--                        </h3>-->
<!--                        <h3 class="text-center w-full" v-else>Coordinador</h3>-->
<!--                    </div>-->
<!--                    <p class="text-3xl text-center pt-2 w-full font-bold pt-2">-->
<!--                        {{ supervisor }}-->
<!--                    </p>-->
<!--                </div>-->
<!--            </a-card>-->
            <a-card :loading="payload.loading" @click="redirect({ section: 'supervising' })" v-if="payload.user.isCoordinator">
              <div class="divide-y flex items-center justify-center flex-col">
                <h3 class="text-center pb-2 w-full">Supervisando</h3>
                <p class="text-3xl pt-2 text-center w-full font-bold">
                  {{ payload.supervising.length }}
                </p>
              </div>
            </a-card>
            <a-card v-if="payload.user.isTeacher" :loading="payload.loading" @click="redirect({ section: 'coordinators'})">
              <div class="divide-y flex items-center justify-center flex-col">
                <h3 class="text-center w-full pb-2">Supervisores</h3>
                <p class="text-3xl text-center pt-2 w-full font-bold pt-2">
                  {{ payload.supervisors.length }}
                </p>
              </div>
            </a-card>
            <a-card :loading="payload.loading" @click="redirect({ section: 'courses' })">
                <div class="divide-y flex items-center justify-center flex-col">
                    <h3 class="text-center w-full pb-2">Materias</h3>
                    <p class="text-3xl text-center pt-2 w-full font-bold pt-2">
                        {{ payload.courses.length }}
                    </p>
                </div>
            </a-card>
            <a-card :loading="payload.loading" @click="redirect({ section: 'lessons' })">
                <div class="divide-y flex items-center justify-center flex-col">
                    <h3 class="text-center w-full pb-2">Clases</h3>
                    <p class="text-3xl text-center pt-2 w-full font-bold pt-2">
                        {{ payload.lessons.length }}
                    </p>
                </div>
            </a-card>
        </div>
        <div class="w-full px-2">
            <component
                :is="`${$route.query.section || 'courses'}-view`"
                :user="payload.user"
                :loading="payload.loading"
                :courses="payload.courses"
                :lessons="payload.lessons"
                :period="payload.period"
                :supervisors="payload.supervisors"
                :supervising="payload.supervising"
                @specialization-change="specializationChange"
            />
        </div>
    </div>
</template>

<script>
import CoordinatorsView from './coordinators'
import CoursesView from './courses'
import LessonsView from './lessons'
import SupervisingView from './supervising'
export default {
    components: {
        CoordinatorsView,
        CoursesView,
        LessonsView,
        SupervisingView
    },
    data: () => ({
        selected: {
            supervisor: null,
            specialization: null
        },
        loadings: {
            supervisor: false
        }
    }),
    methods: {
        specializationChange(id, specialization) {
            if (id && specialization) {
                this.loadings.supervisor = !this.loadings.supervisor
                this.$repository.specializations
                    .coordinator(id)
                    .then(res => {
                        this.selected.supervisor = res.data
                        this.selected.specialization = specialization
                    })
                    .finally(() => this.loadings.supervisor = !this.loadings.supervisor)
            } else {
                this.selected.specialization = null
                this.selected.supervisor = null
            }
        },
        redirect(query) {
            this.$router.replace({ query })
                .catch(() => null)
        }
    },
    computed: {
        supervisor() {
            if (this.selected.supervisor) {
                return  `${this.selected.supervisor.first_name} ${this.selected.supervisor.last_name}`
            }

            return this.payload.supervisor ? `${this.payload.supervisor.first_name} ${this.payload.supervisor.last_name}` : '-'
        }
    },
    props: {
        payload: { required: true, type: Object }
    },
    mounted() {
      if(this.payload.user.isCoordinator) {
        this.redirect({ section: 'supervising' })
      }
    }
}
</script>
