<template>
    <div>
        <slot :payload="{
            binnacles,
            absences,
            delays,
            lessons,
            latestInscription,
            courseSections,
            loading,
            student
        }" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    props: {
        user: { required: true, type: Object },
        period: { required: false, type: Number, default: null }
    },
    computed: {
        ...mapState({
            student: ({ students }) => students.current,
            loading: ({ students }) => students.loading,
        }),
        binnacles() {
            return (this.student?.binnacles ?? [])
                .filter($0 => this.period ? $0.period_id === this.period : true)
        },
        absences() {
            return (this.student?.absences_by_attendance ?? [])
                .filter($0 => this.period ? $0.period_id === this.period : true)
        },
        delays() {
            return (this.student?.absences_by_delay ?? [])
                .filter($0 => this.period ? $0.period_id === this.period : true)
        },
        lessons() {
            return (this.student?.lessons ?? [])
                .filter($0 => this.period ? $0.period_id === this.period : true)
        },
        latestInscription() {
            return this.student?.latest_inscription ?? { period: {}, specialization: {}, semester: {} }
        },
        courseSections() {
            return (this.student?.course_sections ?? [])
                .filter($0 => this.period ? $0.period_id === this.period : true)
        }
    },
    methods: mapActions({
        fetch: 'students/show'
    }),
    mounted() {
        this.fetch({
            id: this.user.student.id,
            query: {
                with: `binnacles.teacher,binnacles.period,binnacles.courseSection.course,binnacles.courseSection.section,binnacles.courseSection.specialization,absencesByAttendance.lesson.teacher,absencesByAttendance.course,absencesByDelay.lesson.teacher,absencesByDelay.course,lessons.teacher,lessons.course,latestInscription.specialization,latestInscription.period,latestInscription.semester,courseSections.course`
            }
        })
    }
}
</script>