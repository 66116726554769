<template>
    <a-table :source="filteredCourses" :columns="columns" rounded :loading="loading">
        <template v-slot:toolbar:search>
            <h2 class="text-2xl p-2">Materias asignadas</h2>
        </template>
        <template #filter(specialization)>
            <select v-model="filters.specialization.id" @change="handleSpecializationChange($event, filters.specialization.deletes)" class="p-3 border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary rounded-lg w-full">
                <option value="">-- TODOS --</option>
                <option :value="specialization.id" v-for="(specialization, k) in specializations" :key="k">{{ specialization.title }}</option>
            </select>
        </template>

        <template #filter(semester)>
            <select :disabled="!filters.specialization.id" @change="erase(filters.semester.deletes)" v-model="filters.semester.id" class="disabled:bg-gray-200 p-3 border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary rounded-lg w-full">
                <option value="">-- TODOS --</option>
                <option :value="semester.id" v-for="(semester, k) in semesters" :key="k">{{ semester.number }}</option>
            </select>
        </template>

        <template #filter(section)>
            <select :disabled="!filters.semester.id" v-model="filters.section.id" class="disabled:bg-gray-200 p-3 border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary rounded-lg w-full">
                <option value="">-- TODOS --</option>
                <option :value="section.id" v-for="(section, k) in sections" :key="k">{{ section.letter_code }}</option>
            </select>
        </template>
    </a-table>
</template>

<script>
export default {
    props: {
        courses: { required: false, type: Array, default: () => [] },
        loading: { required: false, type: Boolean, default: false },
        period: { required: false, type: Number, default: null },
    },
    data: () => ({
        filters: {
            specialization: { id: null, deletes: ['semester', 'section'] },
            semester: { id: null, deletes: ['section'] },
            section: { id: null, deletes: [] },
        }
    }),
    methods: {
        handleSpecializationChange(event, filters) {
            this.erase(filters)
            const specialization = this.specializations.find($0 => $0.id === parseInt(event.target.value))
            this.$emit('specialization-change', event.target.value, specialization)
        },
        erase(filters) {
            filters.forEach($0 => {
                this.filters[$0].id = null
            })
        }
    },
    computed: {
        columns() {
            return [
                { title: "Especialidad", key: 'specialization.title', filter: true, keyFilter: 'specialization' },
                { title: "Semestre", key: 'semester.number', filter: true, keyFilter: 'semester' },
                { title: "Sección", key: 'section.letter_code', filter: true, keyFilter: 'section' },
                { title: "Materia", key: 'course.title' },
                { title: "Código", key: 'course.code' },
            ]
        },
        specializations() {
            return this.courses.map($0 => $0.specialization)
                .uniqueBy(($0, $1) => $0.id === $1.id)
                .sort((a, b) => a.title === b.title ? 0 : a.title < b.title ? -1 : 1)
        },
        semesters() {
            const { specialization } = this.filters
            return this.courses.map($0 => $0.semester)
                .filter($0 => $0.specialization_id === specialization.id)
                .uniqueBy(($0, $1) => $0.id === $1.id)
                .sort((a, b) => a.number === b.number ? 0 : a.number < b.number ? -1 : 1)
        },
        sections() {
            const { semester } = this.filters
            return this.courses.map($0 => $0.section)
                .filter($0 => $0.semester_id === semester.id)
                .uniqueBy(($0, $1) => $0.id === $1.id)
        },
        filteredCourses() {
            const { section, specialization, semester } = this.filters
            return this.courses
                .filter($0 => specialization.id ? $0.specialization_id === specialization.id : true)
                .filter($0 => semester.id ? $0.semester.id === semester.id : true)
                .filter($0 => section.id ? $0.section.id === section.id : true)
        }
    }
}
</script>
