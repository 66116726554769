<template>
    <div>
        <div class="bg-white rounded-2xl overflow-hidden">
            <h2 class="text-2xl p-4">Inasistencias</h2>
            <a-table :source="absences" :columns="columns" rounded :loading="loading">
                <template #td(teacher)="{ item }">
                    {{ item.lesson.teacher.first_name }} {{ item.lesson.teacher.last_name }}
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        absences: { required: false, type: Array, default: () => [] },
        loading: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        columns: [
            { title: "Materia", key: 'course.title' },
            { title: "Fecha", key: 'lesson.date' },
            { title: "Tema", key: 'lesson.subject' },
            { title: "Profesor", slot: 'teacher' },
        ]
    })
}
</script>