<template>
    <div class="asd">
        {{ $pick({a: 2, b: 3, c: 4}, 'a', 'c') }}
    </div>
</template>

<script>
export default {

}
</script>