<template>
    <div v-if="user.isCoordinator">
      <h2 class="text-2xl p-4 mb-2">Profesores</h2>
      <div class="flex flex-wrap -mx-2">
            <div class="w-1/2 lg:w-1/3 xl:w-1/5 p-2" v-for="teacher in supervising" :key="teacher.id">
                <a-card @click="$router.push({ name: 'teachers.show', params: { id: teacher.id }})" class="pb-2">
                    <div class="flex items-center flex-col justify-center truncate">
                        <a-avatar :text="teacher.first_name" :picture="teacher.profile_picture" size="64" class="mb-4"/>

                        <p class="font-bold text-lg truncate">
                            {{ `${teacher.first_name} ${teacher.last_name}` }}
                        </p>
                        <p class="truncate">
                            {{ teacher.phone }}
                        </p>
                        <p>
                            {{ teacher.email }}
                        </p>
                    </div>
                </a-card>
            </div>
            <div class="w-full text-center" v-if="!supervising.length && !loading">
                <h2 class="text-2xl mt-4">No supervisas ningun profesor</h2>
            </div>
        </div>
    </div>
    <div v-else>No tienes acceso a esta vista</div>
</template>

<script>
export default {
    props: {
        loading: { required: false, type: Boolean, default: false },
        user: { required: true, type: Object },
        supervising: { required: false, type: Array, default: () => [] }
    }
}
</script>

<style scoped>

</style>
