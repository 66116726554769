<template>
    <div>
        <div class="bg-white rounded-2xl overflow-hidden">
            <div class="flex flex-wrap justify-between items-center -mx-2 px-4">
                <h2 class="text-2xl p-4">Clases</h2>
                <div class="px-2 lg:mt-0">
                    <a-segmented-control v-model="filters.type" :segments="segments"/>
                </div>
            </div>
            <a-table :source="filteredLessons" :columns="columns" rounded :loading="loading">
                <template v-slot:course>
                    <select v-model="filters.course" class="p-3 border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary rounded-lg w-full">
                        <option value="">-- TODOS --</option>
                        <option :value="course.value" v-for="(course, k) in courses" :key="k">{{ course.label }}</option>
                    </select>
                </template>
                <template #td(teacher)="{ item }">
                    {{ item.teacher.first_name }} {{ item.teacher.last_name }}
                </template>
                <template #td(note)="{ item }">
                    <b>
                        <template v-if="item.evaluated">
                            <span :class="item.pivot.note === null ? 'text-gray-400' : 'text-gray-700'">
                                {{
                                    item.pivot.note === null
                                        ? 'Aun no se ha evaluado'
                                        : item.pivot.note
                                }}
                            </span>
                        </template>
                        <template v-else>
                            <span class="text-orange-400">No se evalua</span>
                        </template>
                    </b>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lessons: { required: false, type: Array, default: () => [] },
        loading: { required: false, type: Boolean, default: false },
        period: { required: false, type: Number, default: null }
    },
    data: () => ({
        columns: [
            { title: "Tema", key: 'subject' },
            { title: "Fecha", key: 'date' },
            { title: "Materia", key: 'course.title', filter: true, keyFilter: 'course' },
            { title: "nota", slot: 'note' },
            { title: "Profesor", slot: 'teacher' },
        ],
        segments: [
            { id: 0, title: "Todas" },
            { id: 1, title: "Evaluadas" },
            { id: 2, title: "Sin evaluar" },
            { id: 3, title: "No se evaluan" },
        ],
        filters: {
            course: null,
            type: 0
        }
    }),
    computed: {
        courses() {
            let courses = []

            this.lessons
                .map($0 => $0.course)
                .forEach($0 => {
                    if (!courses.some($1 => $1.id === $0.id)) {
                        courses.push($0)
                    }
                })

            return courses.map($0 => ({
                label: $0.title,
                value: $0.id
            }))
        },
        filteredLessons() {
            const { course, type } = this.filters
            return this.lessons
                .filter($0 => course ? $0.course_id === course : true)
                .filter($0 => {
                    /*
                    * 0 -> all,
                    * 1 -> evaluated
                    * 2 -> no evaluated
                    * 3 -> unvaluable
                    */
                    switch(type) {
                        case 1: return $0.pivot.note !== null
                        case 2: return $0.pivot.note === null && $0.evaluated === true
                        case 3: return $0.evaluated === false
                        default: return true;
                    }
                })
        }
    }
}
</script>
