<template>
    <div>
        <div class="flex justify-end items-center space-x-2 mb-2" v-if="!user.isAdmin">
            <a-select
                class="w-48"
                placeholder="Periodos"
                :loading="loading"
                v-model="period"
                :source="periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))" />

            <div v-if="shouldShowInscriptionButton" class="flex items-center">
                <a-button class="animate-pulse" @click="reinscript" v-if="enabled_inscriptions">Inscribirme</a-button>
                <template v-else>
                    <span class="text-sm text-gray-600">No se han habilitado las inscripciones</span>
                </template>
            </div>
        </div>

        <component :is="infoComponent" :user="user" :period="period" :period-full="periodFull" #default="{ payload }">
          <component :payload="{...payload, period, selectedPeriod, enabledPeriod}" :is="dashboardComponent" />
        </component>

        <a-modal
            :disabled-confirm="isConfirmInscriptionButtonDisabled"
            :show="modals.inscription"
            @cancel="modals.inscription = !modals.inscription"
            :confirm-text="loadings.inscription ? 'Procesando...' : `Confirmar`"
            @confirm="modals.confirmInscription = true">
            <div v-if="student">
                <template v-if="passed">
                    <h2 class="text-2xl">Felicidades! Puedes avanzar al siguiente semestre</h2>
<!--                    <h3 class="text-xl mt-2">-->
<!--                        Al presionar "confirmar", te estarás inscribiendo en el semestre {{ student.latest_inscription.semester.number + 1 }} {{ student.latest_inscription.semester.number !== 2 ? ' de ' + student.latest_inscription.specialization.title : '' }}-->
<!--                    </h3>-->
                    <template v-if="isBasic">
                        <template v-if="student.latest_inscription.semester.number === 2">
                            <h3 class="text-xl mt-2" v-if="inscription.specialization_id">
                              Al presionar <b>"confirmar"</b>, te estarás inscribiendo en <b>{{ specializations ? specializations.find($0 => $0.value === inscription.specialization_id).label : '' }} </b>
                            </h3>
                            <h3 class="text-xl mt-2">
                                Para avanzar al tercer semestre debes seleccionar una especialidad
                            </h3>
                            <a-select
                                :source="specializations"
                                v-model="inscription.specialization_id"
                                :loading="loadings.specializations"
                                :disabled="loadings.specializations"
                                class="mt-4"
                                v-if="shouldDisplaySpecializationSelect"/>
                            <!--                            <template v-if="specializationCourses.length">-->
<!--                                <h3 class="my-2">Estas son algunas de las materias que verás</h3>-->
<!--                                {{ specializationCourses.map($0 => $0.title).join(', ') }}-->
<!--                            </template>-->
                          <template v-if="specializationCourses.length">
                            <h3 class="my-2">Estas son las materias que verás</h3>
                            {{ specializationCourses.map($0 => $0.title).join(', ') }}
                          </template>
                        </template>
                    </template>
                    <template v-else-if="failedCourses.length != 0">
                        <h3 class="text-xl mt-2">
                          Al presionar <b>"confirmar"</b>, te inscribirás en el <b>semestre {{ student.latest_inscription.semester.number + 1 }}</b> de <b>{{ currentSpecialization.title }}</b> con materias del <b>semestre {{ student.latest_inscription.semester.number}}</b>
                        </h3>
                        <h3 class="my-2">Estas son algunas de las materias que verás</h3>
                        <h2 class="my-2 font-bold text-red-500">Materias del semestre {{ student.latest_inscription.semester.number }}</h2>
                        {{ failedCourses.map($0 => $0.label).join(', ') }}
                        <h2 class="my-2 font-bold">Materias del semestre {{ student.latest_inscription.semester.number + 1 }}</h2>
<!--                        {{ loadings.specializations-->
<!--                            ? 'Cargando...'-->
<!--                            : !specializationCourses.length-->
<!--                                ? '&#45;&#45; No hay siguientes materias'-->
<!--                                : specializationCourses.map($0 => $0.title).join(', ')-->
<!--                        }}-->
                      {{ nextCourses.map($0 => $0.title).join(', ') }}
                    </template>
                    <template v-else-if="failedCourses.length === 0">
                        <h3 class="text-xl mt-2">
                          Al presionar <b>"confirmar"</b>, te inscribirás en el <b>semestre {{ student.latest_inscription.semester.number + 1 }}</b> de <b>{{ currentSpecialization.title }}</b>
                        </h3>
                        <h3 class="my-2 font-bold">Estas son las materias que verás</h3>
                         {{ nextCourses.map($0 => $0.title).join(', ') }}
                    </template>
                    
                </template>
                <template v-else>
                    <template>
                        <h2 class="text-2xl text-center mb-4">Parece que no has conseguido aprobar</h2>
<!--                        <h2 class="text-xl text-center mb-4">¿Repetir el semestre {{ student.latest_inscription.semester.number }} en Básica?</h2>-->
                        <h3 class="text-lg text-center my-2 text-gray-800">
                            {{ isBasic ? 'No has logrado cumplir todos los requisitos para este semestre.' : 'No has logrado aprobar todas las materias' }}
                        </h3>
                        <h2 class="text-xl text-center mt-4 text-red-500">
                          <template v-if="student.latest_inscription.semester.number <= 2">
                            Al presionar <b>"Confirmar"</b> te inscribiras en <b>{{ student.latest_inscription.specialization.title }}</b> con todas sus materias
                          </template>
                          <template v-else>
                            Al presionar <b>"Confirmar"</b> te inscribiras en el <b>semestre {{ student.latest_inscription.semester.number }}</b> de <b>{{ student.latest_inscription.specialization.title }}</b>
                          </template>
                        </h2>
                        <template v-if="student && failedCourses">
                          <div class="w-full flex items-center">
                            <h3 class="flex-auto my-2 text-red-500"><b>{{ isBasic ? 'Estas son las materias que reprobaste.' : 'Estas son las materias que verás.' }}</b></h3>
                            <p v-if="isBasic" class="flex-none">Promedio: {{ averageNote }}</p>
                          </div>
                          {{ failedCourses.map($0 => $0.label).join(', ') }}
                        </template>
                       
                    </template>
                </template>
                <div class="flex flex-wrap -mx-2 text-left mt-4">
                    <div class="w-1/2 px-2">
                        <a-input required label="Fecha" id="payment_date" type="date" v-model="inscription.payment_date" />
                    </div>
                    <div class="w-1/2 px-2">
                      <a-select
                          label="Tipo de pago"
                          required
                          class="mb-4"
                          :source="[{label: 'Efectivo', value: 1}, {label: 'Transferencia bancaria', value: 3}, {label: 'Zelle', value: 5}]"
                          v-model="inscription.payment_type" />
                    </div>
                    <div class="w-1/2 px-2">
                        <a-input required label="Monto" id="payment_amount" v-model="inscription.payment_amount" />
                    </div>
                    <div class="w-1/2 px-2">
                      <a-input
                          :required="inscription.payment_type !== 1"
                          :disabled="inscription.payment_type === 1"
                          label="Número de recibo"
                          id="payment_receipt_number"
                          v-model="inscription.payment_receipt_number" />
                    </div>
                </div>
            </div>
        </a-modal>
        <a-modal
            :show="modals.confirmInscription"
            @confirm="doReinscription"
            @cancel="modals.confirmInscription = false"
        >
            <div class="text-center" v-if="passed">
                <h2 class="text-2xl text-red-500 align-center">Advertencia</h2>
                <h3 class="text-xl mt-2">Una vez enviada la información, no podrás cambiarla</h3>
                <h3 class="text-xl mt-2">¿Desea continuar?</h3>
            </div>
            <div v-else>
                <h2 class="text-2xl text-red-500 align-center">Advertencia</h2>
                <h3 class="text-xl mt-2">No cuentas con el promedio requerido para avanzar al siguiente semestre. Serás inscrito en el nuevo periodo cursando el mismo semestre.</h3>
                <h3 class="text-xl mt-2">¿Desea continuar?</h3>
            </div>

        </a-modal>
    </div>
</template>

<script>
import { StudentInfo, StudentDashboard } from './_user_info/student'
import { TeacherInfo, TeacherDashboard } from './_user_info/teacher'
import { AdministratorInfo, AdministratorDashboard } from './_user_info/administrator'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    components: {
        StudentInfo,
        StudentDashboard,

        TeacherInfo,
        TeacherDashboard,

        AdministratorInfo,
        AdministratorDashboard
    },
    computed: {
        ...mapState({
            user: ({ session }) => session.user
        }),
        isConfirmInscriptionButtonDisabled() {
            if (this.passed) {
              const keys = ['payment_date', 'payment_amount', 'payment_type', 'specialization_id']
                if (this.inscription.payment_type !== 1) {
                    keys.push('payment_receipt_number')
                }
                return keys.some($0 => !this.inscription[$0] || this.inscription[$0] === null || this.inscription[$0] === '')
            } else {
              const keys = ['payment_date', 'payment_amount', 'payment_type']
                if (this.inscription.payment_type !== 1) {
                    keys.push('payment_receipt_number')
                }
              return keys.some($0 => !this.inscription[$0] || this.inscription[$0] === null || this.inscription[$0] === '')
            }

        },
        ...mapGetters(['lang']),
        infoComponent() {
            if (this.user.isStudent) return 'student-info'
            if (this.user.isTeacher || this.user.isCoordinator) return 'teacher-info'
            if (this.user.isAdmin) return 'administrator-info'

            return 'administrator-info'
        },
        dashboardComponent() {
            return this.infoComponent.replace('info', 'dashboard')
        },
        shouldShowInscriptionButton() {
            if (!this.user.isStudent) return false;

            if (this.latest_inscription === 6) {
              return !this.passed;
            } else {
              return (this.student?.latest_inscription?.period_id !== this.enabled_inscriptions?.id) && this.student
            }
        },
        isFromFirstSemester() {
            return this.student?.latest_inscription?.semester?.number === 1
        },
        shouldDisplaySpecializationSelect() {
            return (this.student?.latest_inscription?.semester?.number ?? 1) !== 1
        },
        shouldDisplayConfirmInscriptionButton() {
            return (this.inscription.specialization_id && this.courses.length) || this.student?.latest_inscription?.semester?.number === 1
        },
        enabledPeriod() {
            return this.periods.find($0 => $0.enabled) ?? { enabled_inscriptions: false }
        },
        enabledPreregistersPeriod() {
            return true
        },
        selectedPeriod() {
            return this.periods.find($0 => $0.id === this.period)
        },
        specializationCourses() {
            return this.specializations.find($0 => $0.value === this.inscription.specialization_id)
                ?.semesters
                ?.find($0 => $0.number === this.student.latest_inscription.semester.number + 1).courses
                // ?.map($0 => $0.courses)
                // ?.flatMap($0 => $0)
                ?.filter(($0, index, self) => self.findIndex($1 => $1.id === $0.id) === index)
                ?.slice(0, 5) ?? []
        }
    },
    methods: {
        ...mapActions({
            fetchStudent: 'students/show'
        }),
        doReinscription(force_on_fail = true) {
            let payload = {
                ...this.inscription,
                force_on_fail
            }

            this.$repository.students
                .inscription(this.student?.id, payload)
                .then(() => {
                    this.student.latest_inscription.period_id = this.enabledPeriod?.id
                    this.modals.inscription = false
                    this.modals.confirmInscription = false
                    this.$message.success({
                        message: `La inscripción se ha realizado con éxito`,

                    })

                    this.fetchStudent({
                        id: this.user.student.id,
                        query: {
                            with: `binnacles,absencesByAttendance.lesson.teacher,absencesByAttendance.course,absencesByDelay.lesson.teacher,absencesByDelay.course,lessons.teacher,lessons.course,latestInscription.specialization,latestInscription.period,latestInscription.semester,courseSections.course`
                          }
                        })
                })
                ._loading(() => this.loadings.inscription = !this.loadings.inscription)
        },
        
        reinscript() {
          this.$repository.students
            .verifySemesterApproval(this.student?.id)
            .then((response) => {
              this.isBasic = response.data.is_basic
              this.passed = response.data.passed
              this.error_message = response.data.message
              this.currentSpecialization = response.data.current_specialization

              if (response.data.passed) {
                if (this.student.latest_inscription.semester.number === 2) {
                  this.modals.inscription = true

                  this.$repository.specializations.index({limit: 0, with: 'semesters.courses'})
                    ._loading(() => (this.loadings.specializations = !this.loadings.specializations))
                    .then(({data: specializations}) => {
                      this.specializations = specializations.filter($0 => $0.id !== 1).map($0 => ({
                        label: $0.title,
                        value: $0.id,
                        semesters: $0.semesters
                      }))
                    })
                } else if (this.student.latest_inscription.semester.number === 6) {
                  this.modals.inscription = false

                  this.$message.warning({
                    title: 'No se puede inscribir',
                    message: 'Ya has aprobado el último semestre'
                  })
                } else { 

                  if (this.isBasic === false) { 
                    this.modals.inscription = true

                    this.inscription.specialization_id = response.data.current_specialization.id
                    this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.title, value: $0.id }))
                    this.nextCourses = response.data.details.next_semester_courses
                  }
                  this.modals.inscription = true

                  // this.$repository.students
                  //   .nextSemester(this.student?.id)
                  //   .then(({data}) => {
                  //     this.modals.inscription = true
                  //     this.inscription.specialization_id = data.specialization_id
                  //     if(this.failedCourses.length != 0 ){
                  //       this.nextCourses = data.details.next_semester_courses
                  //     } else{ this.nextCourses = data.courses }
                  //   })
                }
              } else {
                this.passed = false
                this.modals.inscription = true
                this.averageNote = response?.data?.details?.courses_avg
                if(this.isBasic){
                  this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.course.title, value: $0.course.id }))
                }else{
                  this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.title, value: $0.id }))
                }
                this.inscription.force_on_fail = true
              }
            })

            // this.$message.loading({
            //     message: 'Verificando datos',
            //     position: 'top-right',
            //     asyncAction: ({ close }) => {
            //         this.$repository.students
            //             .verifySemesterApproval(this.student?.id)
            //             .then(({ data: { is_basic, passed, message, current_specialization: specialization, current_semester: semester } }) => {
            //                 this.isBasic = is_basic
            //                 this.passed = passed
            //                 this.error_message = message
            //                 return { specialization, semester }
            //             })
            //             .then(({ specialization }) => {
            //
            //                 this.inscription.specialization_id = specialization.id
            //
            //                 if (this.student.latest_inscription?.semester?.number === 1) {
            //                     this.$repository.students
            //                         .nextSemester(this.student?.id)
            //                         .then(({ data }) => {
            //                             this.modals.inscription = true
            //                             this.inscription.specialization_id = data.specialization_id
            //                             this.courses = data.courses
            //                         })
            //                 } else {
            //
            //                     this.modals.inscription = true
            //
            //                     this.$repository.specializations.index({ limit: 0, with: 'semesters.courses' })
            //                         ._loading(() => this.loadings.specializations = !this.loadings.specializations)
            //                         .then(({ data: specializations }) => this.specializations = specializations.filter($0 => $0.id !== 1)
            //                             .map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters })
            //                         ))
            //                 }
            //             })
            //             .catch((error) => {
            //                 if (error?.response?.data?.error_code === 'EIN001' ) {
            //                     this.passed = false
            //                     this.modals.inscription = true
            //                     this.inscription.force_on_fail = true
            //                 }
            //             })
            //             .finally(() => {
            //                 close()
            //             })
            //     }
            // })
        },
        initial() {
            this.$repository.periods.index({ limit: 0 })
                ._loading(() => this.loading = !this.loading)
                .then(({ data: periods }) => {
                    this.periods = periods
                    this.period = periods.find($0 => $0.enabled)?.id
                    // console.log(periods.find($0 => $0.enabled))
                    this.periodFull = periods.find($0 => $0.enabled)
                })

            if (this.user.isStudent) {
                this.$repository.students
                    .show(this.user.student.id, {
                        with: `latestInscription.specialization,latestInscription.semester`
                    })
                    ._loading(() => this.loading = !this.loading)
                    .then(({ data }) => {
                        this.student = data

                        this.latest_inscription = data.latest_inscription.semester.number

                        if ( data.latest_inscription.semester.number === 6 ) {
                          this.$repository.students
                            .verifySemesterApproval(this.student?.id)
                            .then(({ data: { passed, current_semester: semester }}) => {
                              this.passed = passed
                              this.latest_inscription = semester.number
                            })
                        }
                    })
            }
        }
    },
    
    data: () => ({
        periods: [],
        courses: [],
        specializations: [],
        loading: false,
        period: null,
        periodFull: {},
        student: null,
        modals: {
            inscription: false,
            confirmInscription: false
        },
        error_message: null,
        passed: false,
        isBasic: true,
        inscription: {
          payment_receipt_number: ''
        },
        loadings: {
            specializations: false,
            inscription: false
        },
        averageNote: 0,
        currentSpecialization: [],
        nextCourses: [],
        failedCourses: [],
        latest_inscription: null,
        enabled_inscriptions: false
    }),
    watch: {
          'inscription.payment_type'(val) {
            if(val === 1 ) { this.inscription.payment_receipt_number = null }
          }
        },
    mounted() {
      this.$repository.preinscriptions.enabledInscriptionsPeriod()
        .then(({ data: res }) => {
            this.enabled_inscriptions = res.enabled_inscriptions
        }).catch(() => {
            this.$message.closeAll()
          this.enabled_inscriptions = false
          // this.$message.warning({
          //     duration: 0
          // })
          // this.$message.closeAll()
        })
      this.initial()
    }
}
</script>
