<template>
  <div class="flex flex-col space-y-4 justify-center -mx-2">
    <section>
      <div class="w-full px-2">
        <div class="flex flex-wrap space-x-2 justify-between mb-2 items-end">
          <h3 class="text-2xl">Datos de inscripción</h3>
          <h4 class="text-xl" v-if="payload.enabledPeriod.semester_price_e2">
            Costo del semestre: {{ `${payload.enabledPeriod.semester_price_e2 / 100} USD` }}
          </h4>
          <a-dropdown v-if="student">
            <template #menu>
              <a-dropdown-item :to="{ name: 'profile', params: { id: student.id } }">
                Ir al pefil
              </a-dropdown-item>
              <a-dropdown-item :to="{ name: 'profile.historic', params: { id: student.id } }">
                Ver historial academico
              </a-dropdown-item>
              <a-dropdown-item :to="{ name: 'profile.payments', params: { id: student.id } }">
                Ver registro de pagos
              </a-dropdown-item>
              <a-dropdown-item :to="{ name: 'profile.inscriptions', params: { id: student.id } }">
                Ver inscripciones
              </a-dropdown-item>
            </template>
          </a-dropdown>
        </div>
        <a-card :loading="payload.loading">
          <div class="flex flex-wrap -mx-2">
            <div class="w-1/2">
              <p class="px-2 text-lg">
                <span class="font-bold">Período</span>: {{ `${payload.latestInscription.period.year}-${payload.latestInscription.period.number}` }}
              </p>
              <p class="px-2 text-lg">
                <span class="font-bold">Semestre</span>: {{ payload.latestInscription.semester.number }}
              </p>
              <p class="px-2 text-lg mb-4 flex items-center">
                <span class="font-bold">Especialidad</span>:
                <span class="inline-block w-4 h-4 mx-2 rounded-full" :style="{
                              color: payload.latestInscription.specialization.text_color,
                              background: payload.latestInscription.specialization.color,
                          }"></span>
                {{ payload.latestInscription.specialization.title }}
              </p>
            </div>
            <div class="w-1/2 px-2 text-lg text-right">
              <a-button class="button" @click="showTagsModal = true">Etiqueta</a-button>
            </div>

            <p class="w-1/2 px-2 text-lg">
              <span class="font-bold">Fecha de primer corte</span>: {{ payload.latestInscription.period.first_half_end_date }}
            </p>

            <p class="w-1/2 px-2 text-lg">
              <span class="font-bold">Fecha de segundo corte</span>: {{ payload.latestInscription.period.second_half_end_date }}
            </p>

            <p class="w-1/2 px-2 text-lg">
              <span class="font-bold">Fecha de inicio</span>: {{ payload.latestInscription.period.start_date }}
            </p>

            <p class="w-1/2 px-2 text-lg">
              <span class="font-bold">Fecha de cierre</span>: {{ payload.latestInscription.period.end_date }}
            </p>
          </div>
        </a-card>
      </div>
    </section>

    <section class="flex">
      <div class="w-1/2 md:w-1/3 xl:w-1/5 px-2">
        <a-card :loading="payload.loading" @click="$router.replace({ query: { section: 'courses' } }).catch(() => null)">
          <div class="divide-y">
            <h3 class="text-center">Materias</h3>
            <p class="text-3xl text-center font-bold">
              {{ courses.length }}
            </p>
          </div>
        </a-card>
      </div>
      <div class="w-1/2 md:w-1/3 xl:w-1/5 px-2">
        <a-card :loading="payload.loading" @click="$router.replace({ query: { section: 'lessons' } }).catch(() => null)">
          <div class="divide-y">
            <h3 class="text-center">Clases</h3>
            <p class="text-3xl text-center font-bold">
              {{ payload.lessons.length }}
            </p>
          </div>
        </a-card>
      </div>
      <div class="w-1/2 md:w-1/3 xl:w-1/5 px-2">
        <a-card :loading="payload.loading" @click="$router.replace({ query: { section: 'absences' } }).catch(() => null)">
          <div class="divide-y">
            <h3 class="text-center">Inasistencias</h3>
            <p class="text-3xl text-center font-bold">
              {{ payload.absences.length }}
            </p>
          </div>
        </a-card>
      </div>
      <div class="w-1/2 md:w-1/3 xl:w-1/5 px-2">
        <a-card :loading="payload.loading" @click="$router.replace({ query: { section: 'delays' } }).catch(() => null)">
          <div class="divide-y">
            <h3 class="text-center">Retrasos</h3>
            <p class="text-3xl text-center font-bold">
              {{ payload.delays.length }}
            </p>
          </div>
        </a-card>
      </div>
      <div class="w-1/2 md:w-1/3 xl:w-1/5 px-2">
        <a-card :loading="payload.loading" @click="$router.replace({ query: { section: 'binnacles' } }).catch(() => null)">
          <div class="divide-y">
            <h3 class="text-center">Bitácoras</h3>
            <p class="text-3xl text-center font-bold">
              {{ payload.binnacles.length }}
            </p>
          </div>
        </a-card>
      </div>
    </section>

    <!-- <div class="w-1/2 md:w-1/3 lg:w-1/4 px-2">
        <a-card :loading="payload.loading">
            <div class="divide-y">
                <h3 class="text-center text-3xl mt-3 -mb-3">Historial académico</h3>
            </div>
        </a-card>
    </div> -->

    <div class="w-full px-2" v-if="payload">
      <component
        :is="`${$route.query.section || 'courses'}-view`"
        :loading="payload.loading"
        :courses="courses"
        :absences="payload.absences"
        :delays="payload.delays"
        :lessons="payload.lessons"
        :period="payload.period"
        :periodId="payload"
        :selected-period="payload.selectedPeriod"
        :user="payload.student"
        :binnacles="payload.binnacles" />
    </div>
    <a-modal
      :show="showTagsModal"
      :loading="loading.tag">
      <label for="tagTitle">Título</label>
      <a-input type="text" id="tagTitle" v-model="tagTitle"/>
      <label for="tagCourse" class="block mt-4">Materia</label>
      <select id="tagCourse" v-model="selectedCourseId" class="block">
        <option v-for="(courseId, key) in courses" :key="key" :value="courseId.course_id">{{ courseId.course.title }}</option>
      </select>
      <template #footer>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
          <span class="flex bg-primary w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              :disabled="!tagTitle || selectedCourseId === null || loading.tag"
              type="button"
              @click="loadingTag"
              class="bg-primary inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed">
              <a :href="`${url}/api/download-work-cover?student_id=${payload.student.id}&course_id=${selectedCourseId}&section_id=${sectionId}&work_title=${tagTitle}`" download>Confirmar</a>
            </button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="showTagsModal = false">
                Cancelar
            </button>
          </span>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import CoursesView from './courses';
import LessonsView from './lessons';
import AbsencesView from './absences';
import DelaysView from './delays';
import BinnaclesView from './binnacles'
import { URL } from '@/constants'

export default {
  components: {
    CoursesView,
    LessonsView,
    AbsencesView,
    DelaysView,
    BinnaclesView
  },
  props: {
    payload: { required: true, type: Object },
  },
  data() {
    return {
      direction: URL,
      showTagsModal: false,
      tagTitle: '',
      sectionId: 0,
      selectedCourseId: null,
      loading: {
        tag: false
      }
    }
  },
  methods: {
    loadingTag() {
      this.loading.tag = true
      window.setTimeout(this.loadingAndModal, 2000)
    },
    loadingAndModal(){
      this.showTagsModal = false
      this.loading.tag = false
    },
    createTag() {
      // ?student_id=979&course_id=5&section_id=120&work_title=Prueba
      this.loading.tag = true
      this.$repository.students
        .downloadWorkCover({
          student_id: this.payload.student.id,
          course_id: this.selectedCourseId,
          section_id: this.sectionId,
          work_title: this.tagTitle
        })
        .then((response) => (console.log(response)))
        .catch(() => {})
        .finally(() => {
          this.showTagsModal = false
          this.loading.tag = false
        })
    },
    setSectionId(value) {
      console.log('from set: ', value)
      this.sectionId = value
    }
  },
  computed: {
    courses() {
      return this.payload.courseSections
    },
    student() {
      return this.payload.student
    },
    url() {
      return this.direction
    }
  },
  watch: {
    selectedCourseId(value) {
      this.courses.filter(course => {
        if (course.course_id === value) {
          this.setSectionId(course.section_id)
        }
      })
    }
  }
}
</script>
